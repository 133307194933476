import React from "react";
import logo_dark from "../../assets/images/oktogrid/Group 83-dark.svg";
import logo_light from "../../assets/images/oktogrid/Group 82.png";
import "../../assets/libs/@mdi/font/css/materialdesignicons.min.css";
import "../../assets/css/tailwind.css";
// import { useState, useEffect } from 'react';
// import { Link, useLocation } from 'react-router-dom'

export default function NavbarTwo(props) {
  let { navClass, navJustify } = props;
  // // let [isMenu, setisMenu] = useState(false);
  // let [manu , setManu] = useState('');
  let location = window.location;

  // useEffect(()=>{
  //     let current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
  //     setManu(current)

  //     function windowScroll() {
  //         const navbar = document.getElementById("topnav");
  //         if (
  //             document.body.scrollTop >= 50 ||
  //             document.documentElement.scrollTop >= 50
  //         ) {
  //             if (navbar !== null) {
  //                 navbar?.classList.add("nav-sticky");
  //             }
  //         } else {
  //             if (navbar !== null) {
  //                 navbar?.classList.remove("nav-sticky");
  //             }
  //         }
  //     }
  //     window.addEventListener("scroll", windowScroll);
  //     window.scrollTo(0, 0)
  //     return () => {
  //         window.removeEventListener('scroll', windowScroll);
  //     };

  // },[location.pathname.substring(location.pathname.lastIndexOf('/') + 1)])

  // const toggleMenu = () => {
  //     setisMenu(!isMenu);
  //     if (document.getElementById("navigation")) {
  //         const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
  //         anchorArray.forEach(element => {
  //             element.addEventListener('click', (elem) => {
  //                 const target = elem.target.getAttribute("href")
  //                 if (target !== "") {
  //                     if (elem.target.nextElementSibling) {
  //                         var submenu = elem.target.nextElementSibling.nextElementSibling;
  //                         submenu.classList.toggle('open');
  //                     }
  //                 }
  //             })
  //         });
  //     }
  // };

  return (
    <nav
      id="topnav"
      className={`defaultscroll tagline-height ${
        navClass === "nav-light"
          ? ""
          : navClass === "nav-sticky"
          ? "bg-white dark:bg-slate-900"
          : ""
      }`}
    >
      <div className="container relative">
        {navClass === "nav-light" ? (
          <a className="logo" href="#">
            <span className="inline-block dark:hidden">
              <img
                src={logo_dark}
                className="l-dark"
                height="68"
                width="138"
                alt=""
              />
              <img
                src={logo_light}
                className="l-light"
                height="68"
                width="138"
                alt=""
              />
            </span>
            <img
              src={logo_light}
              height="24"
              className="hidden dark:inline-block"
              alt=""
            />
          </a>
        ) : (
          <a className="logo" href="#">
            <img
              src={logo_dark}
              className="inline-block dark:hidden"
              alt=""
              height="68"
              width="138"
            />
            <img
              src={logo_light}
              className="hidden dark:inline-block"
              alt=""
              height="68"
              width="138"
            />
          </a>
        )}

        {/* <div className="menu-extras">
                <div className="menu-item">
                    <a href="#" className={`navbar-toggle ${isMenu ? 'open' : ''}`} id="isToggle" onClick={() => toggleMenu()}>
                        <div className="lines">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </a>
                </div>
            </div> */}
        {/* navClass !== 'nav-light' ? <ul className={`buy-button list-none space-x-1 mb-0`} >
                        <li className="inline mb-0">
                            <Link to="#" className="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-indigo-600/5 hover:bg-indigo-600 border border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white"><Icon.Settings className="size-4" /></Link>
                        </li>

                        <li className="inline ps-1 mb-0">
                            <Link to="https://1.envato.market/techwind-react" target="_blank" className="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white"><Icon.ShoppingCart className="size-4" /></Link>
                        </li>
                        
                    </ul>
                    :
                    <ul className="buy-button list-none space-x-1 mb-0">
                    <li className="inline mb-0">
                        <Link to="#">
                            <span className="login-btn-primary"><span className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600/5 hover:bg-indigo-600 border border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white"><Icon.Settings className="size-4" /></span></span>
                            <span className="login-btn-light"><span className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 border hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"><Icon.Settings className="size-4" /></span></span>
                        </Link>
                    </li>
            
                    <li className="inline ps-1 mb-0">
                        <Link to="https://1.envato.market/techwind-react" target="_blank">
                            <div className="login-btn-primary"><span className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white"><Icon.ShoppingCart className="size-4" /></span></div>
                            <div className="login-btn-light"><span className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 border hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"><Icon.ShoppingCart className="size-4" /></span></div>
                        </Link>
                    </li>
                </ul> */}
        {/* <div id="navigation" style={{ display: isMenu ? 'block' : 'none' }}> */}
        <div id="navigation">
          <ul className={`navigation-menu ${navClass} ${navJustify}`}>
            {/* <li className={manu === "index" || "" ? "active" : ""}><Link to="/index" className="sub-menu-item">Home</Link></li> */}
            <li>
              <a href="#services">Services</a>
            </li>

            <li>
              <a href="#advantages">Advantages</a>
            </li>
            {/* <li>
              <a href="#about">About</a>
            </li> */}

            {/* <li>
              <a href="#expertise">Expertise</a>
            </li> */}

            <li>
              <a href="#contact_us" className="sub-menu-item">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
