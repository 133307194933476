import React from "react";
import bg1 from "../../assets/images/business/main-bg.png";

import NavbarTwo from "../../component/Navbar/navbarTwo";
import CountUp from "react-countup";
import Footer from "../../component/Footer/footer";
import AboutOne from "../../component/aboutOne";
import AboutTwo from "../../component/aboutTwo";
import AboutThree from "../../component/aboutThree";
import Form from "../../component/Form/Form";

import { startupAbout, startupServices } from "../../data/dataTwo";

export default function IndexClassicBusiness() {
  return (
    <>
      <NavbarTwo navClass="nav-sticky" />
      <section
        className="relative table mt-16 w-full md:pt-72 md:pb-40 py-36 bg-no-repeat bg-top bg-cover"
        style={{ backgroundImage: `url(${bg1})` }}
      >
        {/* <div className="absolute inset-0 bg-gradient-to-l from-transparent via-white/50 dark:via-slate-900/50 to-white dark:to-slate-900"></div> */}
        <div className="container relative mt-10">
          <div className="grid grid-cols-1">
            <h1 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5">
              Ready to build the best
              <br />
              software solution for your business
            </h1>
            <p className="text-white text-lg max-w-xl dark:text-white">
              Oktagrid is a team of software engineers and designers that builds
              powerful enterprise solutions.
            </p>

            <div className="mt-6">
              <a
                href="#contact_us"
                className="py-2 px-6 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
              >
                Contact us
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="relative overflow-hidden" id="services">
        {/* what we provide block */}
        <section className="relative md:py-24 py-16 dark:bg-slate-800">
          <div className="container relative">
            <div className="grid grid-cols-1 pb-8 text-center">
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                What we provide
              </h3>

              <p className="text-slate-800 max-w-xl mx-auto dark:text-white">
                Start working with Oktagrid to create cutting-edge solutions
                that drive business grow and success.
              </p>
            </div>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
              {startupServices.map((item, index) => {
                let Icons = item.icon;
                return (
                  <div
                    key={index}
                    className="group p-6 rounded-lg shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 hover:-translate-y-2 transition-all duration-500 ease-in-out"
                  >
                    <div className="size-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                      <Icons className="size-6" />
                    </div>

                    <div className="content mt-7">
                      <div className="title h5 text-lg font-medium hover:text-indigo-600">
                        {item.title}
                      </div>
                      {item.desc.map((item) => (
                        <p className="text-slate-400 mt-3">{item}</p>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <div className="container-fluid relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 py-36 bg-[url('../../assets/images/team-1.png')] bg-center bg-no-repeat bg-cover">
            <div className="absolute inset-0 bg-slate-900/10"></div>
            <div className="container relative">
              <div className="grid grid-cols-1 text-center">
                <h3 className="mb-4 md:text-3xl text-2xl text-white font-medium">
                  Turning Vision into Reality: Innovate, Collaborate, Elevate!
                </h3>

                <p className="text-white/80 max-w-xl mx-auto">
                  Let us help you to achieve your goals with Oktagrid's
                  expertise and passion for development.
                </p>

                {/* <Link to="#" onClick={() => setOpen(true)} data-id="S_CGed6E610" className="lightbox size-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-indigo-600 mx-auto mt-10">
                                    <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                </Link> */}
              </div>
              {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="S_CGed6E610" onClose={() => setOpen(false)} /> */}
            </div>
          </div>
        </div>
        <section
          className="relative md:py-24 py-16 dark:bg-slate-800"
          id="advantages"
        >
          <div className="container relative">
            <h3 className="mb-6 md:text-3xl md:leading-normal text-2xl leading-normal text-center font-semibold">
              Why work with us
            </h3>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
              {startupAbout.map((item, index) => {
                let Icons = item.icon;
                return (
                  <div key={index} className="flex">
                    <div className="flex align-middle justify-center items-center min-w-[56px] h-[56px] bg-indigo-600/5 border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-xl shadow-sm dark:shadow-gray-800">
                      <Icons className="size-6" />
                    </div>
                    <div className="content ms-6">
                      <a
                        href="#"
                        className="text-lg font-medium hover:text-indigo-600"
                      >
                        {item.title}
                      </a>
                      <p className="text-slate-400">{item.desc}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
{/* 
        <div
          className="container relative md:mt-1 mt-1 lg:pt-24 pt-16"
          id="about"
        >
          <div className="absolute inset-0 opacity-25 dark:opacity-50 bg-no-repeat bg-center bg-cover"></div>
          <div className="relative grid grid-cols-1 pb-8 text-center z-1">
            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold text-black dark:text-white">
              Global clients around the world
            </h3>

            <p className="text-slate-800 max-w-xl mx-auto dark:text-white">
              With more than 20 years of experience, over 100 professionals and
              over 250 projects delivered we are ready to take each project to
              the next level.
            </p>
          </div>

          <div className="relative grid md:grid-cols-3 grid-cols-1 items-center mt-8 gap-[30px] z-1">
            <div className="counter-box text-center">
              <h1 className="lg:text-5xl text-4xl font-bold mb-2 text-indigo-600">
                <CountUp className="counter-value" start={1} end={250} />+
              </h1>
              <h5 className="counter-head text-lg font-medium">Projects</h5>
            </div>

            <div className="counter-box text-center">
              <h1 className="lg:text-5xl text-4xl font-bold mb-2 text-indigo-600">
                <CountUp className="counter-value" start={1} end={100} />+
              </h1>
              <h5 className="counter-head text-lg font-medium">
                Professionals
              </h5>
            </div>

            <div className="counter-box text-center">
              <h1 className="lg:text-5xl text-4xl font-bold mb-2 text-indigo-600">
                <CountUp className="counter-value" start={1} end={20} />+
              </h1>
              <h5 className="counter-head text-lg font-medium">
                Years Experience
              </h5>
            </div>
          </div>
        </div> */}
        {/* <div className="container relative md:mt-24 py-16" id="expertise">
          <div className="grid md:grid-cols-12 grid-cols-1 pb-8 items-center">
            <div className="md:col-span-12 md:text-start text-center">
              <h3 className="md:mb-0 mb-6 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-center">
                Our expertise
              </h3>
            </div>
          </div>
          <AboutOne />
        </div> */}
        {/* <AboutTwo />
        <AboutThree /> */}
        <Form />
      </section>
      <Footer />
      {/* <CookieModal/> */}
    </>
  );
}
