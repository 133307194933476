import { useForm } from "react-hook-form";
import contact from "../../assets/images/contact_us_img.jpg";
import * as Icon from "react-feather";
import { useState } from "react";

export default function Form() {
  const [formSent, setFormSent] = useState(false);
  const [sentError, setSentError] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
  } = useForm({ mode: "onBlur" });

  const onSubmit = async (data) => {
    await fetch("/sendEmail", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        reset();
        setFormSent(true);
      })
      .catch((err) => {
        setSentError(true);
        console.log("error", err.message);
      })
      .finally(() => {
        setTimeout(() => {
          setFormSent(false);
          setSentError(false);
        }, 5000);
      });
  };
  return (
    <div className="container relative md:py-24 py-16" id="contact_us">
      <h3 className="pb-8 mb-6 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-center">
        Tell us about your project and we’ll get back to you shortly
      </h3>
      <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[40px]">
        <div className="lg:col-span-6 md:col-span-6">
          <img src={contact} alt="" />
        </div>

        <div className="lg:col-span-6 md:col-span-6">
          <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
            {/* <h3 className="mb-6 text-2xl leading-normal font-medium text-center">For Project Inquiries</h3> */}

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-1">
                <div className="lg:col-span-6 mb-2">
                  <div className="text-start">
                    <label htmlFor="name" className="font-semibold">
                      Your Name:
                    </label>
                    <div className="form-icon relative mt-2">
                      <Icon.User className="size-4 absolute top-3 start-4"></Icon.User>
                      <input
                        {...register("requestName", {
                          required: "Required",
                        })}
                        type="text"
                        className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                        placeholder="Name :"
                      />
                    </div>
                    <p className="text-red-500 text-xs italic invisible">
                      Please enter a valid name.
                    </p>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1">
                <div className="mb-2">
                  <div className="text-start">
                    <label htmlFor="email" className="font-semibold">
                      Your Email:
                    </label>
                    <div className="form-icon relative mt-2">
                      <Icon.Mail className="size-4 absolute top-3 start-4"></Icon.Mail>
                      <input
                        {...register("requestEmail", {
                          required: "Required",
                          pattern: {
                            value:
                              /[a-zA-Zа-яА-ЯёЁ0-9._%+-]+@[a-zA-Zа-яА-ЯёЁ0-9._%+-]+\.[a-zA-Zа-яА-ЯёЁ0-9._%+-]+$/,
                            message: "Please enter a valid email.",
                          },
                        })}
                        type="email"
                        className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                        placeholder="Email :"
                      />
                    </div>
                    {/* <p
                      className={`text-red-500 text-xs italic ${
                        errors?.requestEmail ? "visible" : "invisible"
                      }`}
                    >
                      {errors?.requestEmail && errors?.requestEmail.message}
                    </p> */}
                  </div>
                </div>

                <div className="mb-5">
                  <div className="text-start">
                    <label htmlFor="comments" className="font-semibold">
                      Your Comment:
                    </label>
                    <div className="form-icon relative mt-2">
                      <Icon.MessageCircle className="size-4 absolute top-3 start-4"></Icon.MessageCircle>
                      <textarea
                        {...register("requestComments")}
                        className="form-input ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                        placeholder="Message :"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                id="submit"
                name="send"
                className="py-2 px-5 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md justify-center flex items-center"
              >
                Send Project Inquiries
              </button>
              {/* form sent successfully */}
              {formSent && (
                <div
                  className="animate-[wiggle_3s_ease-in] p-4 mt-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
                  role="alert"
                >
                  <span className="font-medium">Form sent successfully</span>
                </div>
              )}
              {/* Something went wrong */}
              {sentError && (
                <div
                  className="animate-[wiggle_3s_ease-in] p-4 mt-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                  role="alert"
                >
                  <span className="font-medium">
                    Something went wrong! Try later
                  </span>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
