import React from "react";
import "./assets/libs/@mdi/font/css/materialdesignicons.min.css";
// import Index from './pages/index';
import IndexClassicBusiness from "./pages/index/index";
import Switch from "./component/Switch";

export default function App() {
  return (
    <>
      <IndexClassicBusiness />
      <Switch />
    </>
  );
}
