export const footerCompany = [
  {
    liClass: "ms-0",
    route: "#services",
    title: "Services",
  },
  {
    liClass: "mt-[10px] ms-0",
    route: "#advantages",
    title: "Advantages",
  },
  // {
  //   route: "#about",
  //   title: "About",
  //   liClass: "mt-[10px] ms-0",
  // },
  // {
  //   route: "#expertise",
  //   title: "Expertise",
  //   liClass: "mt-[10px] ms-0",
  // },
  {
    route: "#contact_us",
    title: "Contact",
    liClass: "mt-[10px] ms-0",
  },
];
