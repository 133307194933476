import React from "react";

import logo_light from "../../assets/images/oktogrid/Group 82.png";

import { footerCompany } from "../../data/data";

export default function Footer() {
  return (
    <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
      <div className="container relative">
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <div className="py-[60px] px-0">
              <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                <div className="lg:col-span-4 md:col-span-12">
                  <a href="#" className="text-[22px] focus:outline-none">
                    <img src={logo_light} alt="Logo" width="138" height="24" />
                  </a>
                  <p className="mt-6 text-gray-300">
                    Start working with Oktagrid to create everything you need
                    for your business to grow.
                  </p>
                </div>

                <div className="lg:col-span-4 lg:justify-self-center md:col-span-4">
                  <h5 className="tracking-[1px] text-gray-100 font-semibold">
                    Navigation
                  </h5>
                  <ul className="list-none footer-list mt-6">
                    {footerCompany.map((data, index) => (
                      <li key={index} className={data.liClass}>
                        <a
                          href={data.route}
                          className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"
                        >
                          {" "}
                          {data.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="lg:col-span-4 lg:justify-self-end md:col-span-4">
                  <h5 className="tracking-[1px] text-gray-100 font-semibold">
                    Contacts
                  </h5>
                  <div className="list-none footer-list mt-6 text-sm">
                    <p>Compass Building,</p>
                    <p>Al Shohada Road,</p>
                    <p>AL Hamra Industrial Zone-FZ,</p>
                    <p>Ras Al Khaimah, United Arab Emirates</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-[30px] px-0 border-t border-slate-800">
        <div className="container relative text-center">
          <div className="grid md:grid-cols-2 items-center">
            <div className="md:text-start text-center">
              <p className="mb-0">
                © {new Date().getFullYear()} Oktagrid. Develop & Support
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
