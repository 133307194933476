import { FaUsers, BsDisplay, BsRocketTakeoff, BsPhone, BsCloud, HiOutlineDocumentText, BsSend, LiaCrosshairsSolid, BiLineChart, FiAirplay,FiFileText, GoRocket,GoClock, LuUsers,LuSearch, RiPresentationFill, LiaFileInvoiceDollarSolid, RiMoneyCnyBoxLine } from '../assets/icons/icons'


export const about = [
    {
        icon:BiLineChart,
        feature: 'Hign Performance'
    },
    {
        icon:LiaCrosshairsSolid,
        feature: 'Best Securities'
    },
    {
        icon:FiAirplay,
        feature: 'Trusted Service'
    },
    {
        icon: GoRocket,
        feature: 'Info Technology'
    },
    {
        icon:GoClock,
        feature: '24/7 Support'
    },
    {
        icon: LuUsers,
        feature: 'IT Management'
    },
    {
        icon:FiFileText,
        feature: 'Certified Company'
    },
    {
        icon: LuSearch,
        feature: 'Data Analytics'
    },
]
export const appFeature = [
    {
        icon:RiPresentationFill,
        title:"IT consulting services",
        desc:"The best experts for you Helping you to turn the ideas into solution Finding areas that can be improved"
    },
    {
        icon:LiaFileInvoiceDollarSolid,
        title:"Enterprise software solutions",
        desc:"Custom and scalable software that perfectly suits you Industry-specific applications Agile development process"
    },
    {
        icon:FiAirplay,
        title:"Web development",
        desc:"High-performing websites for your needs Easy to manage, integrated in your existing internal system"
    },
    {
        icon:RiMoneyCnyBoxLine,
        title:"Mobile App Development",
        desc:"High-quality and user-friendly apps for iOS and Android platforms"
    },
    {
        icon:RiMoneyCnyBoxLine,
        title:"Support and modification of working solutions",
        desc:"Always in touch if you need support A dedicated team to deal with any arising issues"
    },
    {
        icon:RiMoneyCnyBoxLine,
        title:"API integration",
        desc:"Extensive, independent and agile API integration to work with your IT systems."
    },
]

export const startupAbout = [
    {
        icon:FaUsers,
        title:"Experienced engineers",
        desc:"Deep understanding of software development best practices, industry, standarts and emerging technologies"
    },
    {
        icon:BsDisplay,
        title:"High standards of development",
        desc:"Strive to produce clean, structured and efficient code"
    },
    {
        icon:BsRocketTakeoff,
        title:"The full cycle of creating integrated solutions",
        desc:"From the development of strategy, concept, structure and design, to launch and maintenance"
    },
]
export const startupServices = [
    {
        icon:RiPresentationFill,
        title:"IT consulting services",
        desc: ["The best experts for you", "Helping you to turn the ideas into solution", "Finding areas that can be improved"],
    },
    {
        icon:BsCloud,
        title:"Enterprise software solutions",
        desc: ["Custom and scalable software that perfectly suits you", "Industry-specific applications", "Agile development process"],
    },
    {
        icon:FiAirplay,
        title:"Web development",
        desc:["High-performing websites for your needs", "Easy to manage, integrated in your existing internal system"]
    },
    {
        icon:BsPhone,
        title:"Mobile App Development",
        desc:["High-quality and user-friendly apps for iOS and Android platforms"]
    },
    {
        icon:BsSend,
        title:"Support and modification of working solutions",
        desc:["Always in touch if you need support", "A dedicated team to deal with any arising issues"],
    },
    {
        icon:HiOutlineDocumentText,
        title:"API integration",
        desc:["Extensive, independent and agile API integration to work with your IT systems"]
    },
]
